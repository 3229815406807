@import "mixins/media";

$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol" !default;
$base-font-size: 16px !default;
$base-font-weight: 400 !default;
$small-font-size: $base-font-size * 0.875 !default;

$spacing-unit: 24px !default;

$text-color: #111 !default;
$background-color: #fdfdfd !default;
$brand-color: #21b890 !default;

$grey-color: #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark: darken($grey-color, 25%) !default;

// Width of the content area
$content-width: 900px !default;

// breakpoints
$mobile-up: 320px;
$tablet-up: 460px;

body {
  font: $base-font-weight $base-font-family;
  color: $text-color;
  background-color: $background-color;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

// Custom type styles

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: $spacing-unit / 2;
}

h2,
h3,
h4,
h5,
h6,
p {
  margin-top: $spacing-unit / 3;
}

.question {
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: normal;
  color:#11624C;
}

.media-headline {
  margin-bottom:-16px;
  font-size: 1rem;
}


ol {
  margin-top:1.6rem;
  margin-left:1rem;
}


ul {
  margin-top:1.6rem;
  margin-left:1rem;
}

li {margin-top:.3rem;}


a:not([class]),
.brand-link {
  color: darken($brand-color, 20%);
  text-decoration: underline;

  &:hover {
    background-color: darken($brand-color, 20%);
    color: white;
  }
}

.breadcrumb-nav, .breadcrumb-nav a {
  margin-top:1rem;
  font-size:.8rem;
}

.caption {
  font-size:1rem;
}

.image-caption {
  font-size:.9rem;
  line-height: 1.2rem;
  margin-bottom:2rem;
}

.blog-date {
  font-size:1rem;
}

.bold {
  font-weight: bold;
}

.text-small, .fa-inline {
  font-size: $small-font-size;
}

// Layout


img {
  max-width:100%;
}

main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
  flex: 1;
}

.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width: calc(#{$content-width} - (#{$spacing-unit} * 2));
  margin-right: auto;
  margin-left: auto;
  padding: $spacing-unit;
}

.site-header {
  border-top: 5px solid $grey-color-dark;
  border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865;
  margin-bottom: 40px;
}

.header-logo {
  width: 240px;
  margin-right: 80px;
  @media (max-width: $mobile-up) {
    width: 200px;
  }
}

.site-footer {
  border-top: 1px solid $grey-color-light;
}

.float-right {
  float:right;
}

.float-left {
  float:left;
}

.spacer-top {
  margin-top: 2rem;
}

.spacer-bottom {
  margin-bottom: 2rem;
}

.spacer-equal {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

// General Bootstrap overrides
.btn-primary {
  background-color: $brand-color;
  border-color: $brand-color;

  &:hover {
    background-color: darken($brand-color, 20%);
    border-color: darken($brand-color, 20%);
  }

  &:active {
    background-color: darken($brand-color, 15%) !important;
    border-color: darken($brand-color, 15%) !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem lighten($brand-color, 20%) !important;
  }
}

// =======================
// OO custom styles
// =======================

.page-section {
  clear: both;
  margin-bottom: $spacing-unit * 2;
}

.home__event-card {
  margin-bottom: $spacing-unit;
}

.card-img {
    height: 100%;
    object-fit: cover;
  }



// Responsive iframes:
// 1. Wrap your iframe in a div with class="iframe-container".
// 2. Depending on the content of your iframe, you may need to also add a second class called
//    .iframe-container-xyz that includes custom height, padding, etc
//    (see .iframe-container-video and .iframe-container-form-contact examples for video and form content).
// 2. Assign the iframe itself with class .iframe-video, .iframe-form-generic,
//    or a new custom class depending on content.

.iframe-container {
  position: relative;
  width: 100%;
  height: 0;
}

.iframe-container-video {
  padding-bottom: 56.25%;
  margin-bottom: $spacing-unit * 3;
}

.iframe-video {
  position: absolute;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
}

.iframe-container-form-contact {
  overflow: visible;
  min-height:1520px;
}

.iframe-container-form-speaker {
  overflow: visible;
  min-height:2000px;
}

.iframe-form-generic {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height:100%;
  border: none;
}

.iframe-embed-doc {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height:100%;
  min-height:2000px;
  border: none;
}

.responsiveCal {
 position: relative;
 padding-bottom: 75%;
 height: 0;
 overflow: hidden;
}

.responsiveCal iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

// OO projects filter

.project-filter__toolbar {
  display: flex;
  align-items: start;
  margin-top:2rem;

  .dropdown:not(:first-of-type) {
    margin-left: 0.5rem;
  }
}


// OO project badges

.project-badge {
  font-weight: normal;
  padding:6px;
}

// Blog post styling

article { margin-bottom: 40px; }

.PostNav {
  font-size: .8rem;
  display: block;
  width: auto;
  overflow: hidden;
}

.PostNav a {
  font-size: .8rem;
  display: block;
  width: 50%;
  float: left;
  margin: 1em 0;
}

.PostNav .next {
  text-align: right;
}

// Home page styling

.home-excerpt {
  font-weight: normal;
}

.latest-updates {
  margin-bottom:2rem;

}

.index-hero {
  background-image: url("/assets/images/openoakland-blue.png"); /* The image used */
  background-color: #013743;
  height: auto; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  color: #fff;
  padding: 3rem 2rem 2rem 2rem;
  margin-top:-2.4rem;
}

h1.hero {
  margin-bottom: 0px;
  color: #fff;
}

p.hero {
  font-size: 1.6rem;
}

hr.hr-slack {
  border-top: 2px dotted #013743;
  margin:5rem 0rem 3rem 0rem;
}

hr.hr-home {
  border-top: 2px dotted #013743;
  margin:4rem 0rem;
}
