// Bootstrap card overrides: Projects

.projects-h3 {
  margin-top: .8rem;
}

.projects {
  float: left;
  margin-top:1rem;
  margin-bottom:2rem;
}

.projects-btn {
  margin-top:.8em;
  margin-right:.5rem;
}

.projects img,
.projects a img {
  border: 1px #dadada solid;
  width: 100%;
  margin-bottom: 1rem;
}

.projects a img:hover {
  border: 1px #1ab890 solid;
  opacity: 0.9;
  padding: 0px;
}

// Bootstrap card overrides: Steering Committee Include

.steeering-committee-image {
  margin: 0 3rem 0 0;
  height: 150px;
  width: 150px;
}

// Bootstrap card overrides: Calendar/Event cards

.calendar-card {
  margin-bottom: 1rem;
  float:left;
}

.calendar-card-subtitle {
    font-weight:normal;
    font-style: italic;
  }

.calendar-card-h3 {
  margin-top: .8rem;
}

.card-header-h3 {
  margin-top:.3rem;
  line-height:1.5rem;
}

// Bootstrap card overrides: Resource cards


.resource-card-subtitle {
    font-weight:normal;
    font-style: italic;
    font-size: 1rem;
    margin-top: .8rem;
  }

// Bootstrap card overrides: Blog

.shout-out-body {
  padding:0rem;
}

.shout-out-text {
  padding:1.25rem;
  margin-top:0rem;
}

.shout-out-header {
  font-weight:bold;
}



// Give the calendar-card-link left margin only if it's
// the second child.
.btn + .calendar-card-link {
  margin-left: .5rem;
  font-size: .9rem;
}


#announcement {
  margin-top: 3rem;
  margin-bottom:3rem;
}

#announcement .container {
  padding: 0rem 2rem 0rem 2rem;
}

.home-alert {
  margin-top:2rem;
}

#announcement h3 {
  font-size: 2rem;
  font-weight: bold;
  margin-top: -1.5rem;
}

#announcement .secondaryLink {
  margin-left:.5rem;
}

#clear { clear:both; }

// Adjust typography in Bootstrap accordions

#steering-faq h3 {
  font-size: 2rem;
  font-weight: bold;
  margin-top: .5rem;
}
